import { Product } from "@/interfaces/account/Home/Collections";
import { NextPage } from "next";
import Link from "next/link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";
import { useCart } from "@/context/CartContext";
import { Cart } from "@/interfaces/account/Home/Cart";
import { useContext, useState } from "react";
import AuthContext from "@/context/AuthContext";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { URLS } from "@/utils/urls";
import { showToastError, showToastSuccess } from "@/utils/toastFunctions";
import { toastMessages } from "@/constants/toast-messages";

interface Props {
  product: Product;
}

const ProductItem: NextPage<Props> = ({ product }) => {
  let { user, authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access;
  const [isItemAddingToWishlist, setIsItemAddingToWishlist] = useState(false);
  const [isProductInWishlist, setIsProductInWishlist] = useState(
    product.wishlists.includes(user?.user_id)
  );

  const { addItemToCart } = useCart();
  const AddItemToCart = (product: Product) => {
    const cartItem: Cart = {
      product: product,
      quantity: 1,
    };
    addItemToCart(cartItem);
  };

  const ModifyWishlist = async () => {
    setIsItemAddingToWishlist(true);
    try {
      const response = await fetch(URLS?.modifyWishListUrl(product.id), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to_wish_list: !isProductInWishlist,
        }),
        method: "PUT",
      });
      if (response.ok) {
        setIsProductInWishlist(!isProductInWishlist);
        showToastSuccess(
          !isProductInWishlist
            ? toastMessages.productAddedToWishlist
            : toastMessages.productRemovedFromWishList
        );
      } else {
        showToastError("Failed to add to Wishlist");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsItemAddingToWishlist(false);
    }
  };

  return (
    <div className="m-[6px]">
      <div className="max-w-[248.5px] flex flex-col gap-[16px] sm:max-w-[165px] md:max-w-[190px] ">
        <div className="w-[248.5px] rounded-[4px] group relative sm:max-w-[165px] md:max-w-[190px] cursor-pointer sm:h-[160px] md:h-[160px] h-[250px] bg-[#F5F5F5] flex items-center justify-center overflow-hidden border border-[#4444441F]">
          <Link
            href={`/store/${product?.store.slug}/products/${product?.slug}`}
          >
            <Image
              height={153}
              width={284.5}
              src={product?.main_image_thumbnail}
              alt={product?.title}
              className="min-h-[250px] min-w-[248.5px] sm:min-w-[165px] md:min-w-[190px] group-hover:scale-125 ease-in duration-150"
            />
          </Link>
          <div
            onClick={ModifyWishlist}
            className={`hover:opacity-75 w-11 h-11 text-[20px] sm:hidden md:hidden flex items-center justify-center  absolute ${
              isProductInWishlist ? "bg-orange" : "bg-white"
            } ${
              isItemAddingToWishlist && "animate-pulse"
            } p-[8px] transition-all duration-700 translate-y-5 group-hover:translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible rounded-full shadow-[0px_4px_12px_0px_#00000017] top-[12px] right-[12px] `}
          >
            <FavoriteBorderOutlinedIcon
              fontSize="inherit"
              className={`cursor-pointer ${
                isProductInWishlist ? "text-white" : "text-black"
              }`}
            />
          </div>
          <button
            onClick={() => AddItemToCart(product)}
            className="absolute sm:hidden md:hidden bottom-0 bg-orange hover:bg-[#E8794E] w-full h-[41px] font-poppins transition-all duration-700 opacity-0 invisible group-hover:opacity-100 group-hover:visible text-[16px] font-[500] text-white "
          >
            Add to Cart
          </button>
        </div>
        <Link
          href={`/store/${product?.store.slug}/products/${product?.slug}`}
          className="pt-[16px] cursor-pointer "
        >
          <div>
            <h4 className="text-grey-200 hover:text-orange truncate max-w-[248.5px] text-[14px] font-poppins font-[400] ">
              {product?.title}
            </h4>
          </div>
          <div className="flex justify-between gap-[10px] cursor-pointer ">
            <h4 className="text-[14px] line-through text-grey-200 font-poppins font-[400]">
              ₹{product?.price}
            </h4>
            <h4 className="text-[14px] text-grey-400 font-poppins font-[400]">
              ₹{product?.discount_price}
            </h4>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductItem;
