"use client";

import React, { useState } from "react";
import HeroCarousel from "@/components/Frontend/Pages/Home/HeroCarousel";
import Collections from "@/components/Frontend/Pages/Home/Collections";
import FeauturedItems from "@/components/Frontend/Pages/Home/FeaturedItems";
import LimitedTimeOffer from "@/components/Frontend/Pages/Home/LimitedTImeOffer";
import TopVendors from "@/components/Frontend/Pages/Home/TopVendors";
import ShopCategory from "@/components/Frontend/Pages/Home/ShopCategory";
import NewArrivals from "@/components/Frontend/Pages/Home/NewArrivals";
import New from "@/components/Frontend/Pages/Home/New";

const Index = () => {
  const [heroSliderItems] = useState([
    {
      src: "/images/main-banner1.jpg",
      texts: {
        header: "New Fashion Collection",
        sub: "Sale Offer",
        description: "Best Fashion Clothings in the Market",
      },
    },
    {
      src: "/images/main-banner2.jpg",
      texts: {
        header: "Best Headphone Sets",
        sub: "Sale Offer",
        description: "Get Amazing and affordable Head sets for your use case",
      },
    },
  ]);

  return (
    <div className="flex max-w-[100vw] overflow-hidden flex-col gap-[94px] sm:gap-[68px] md:gap-[68px] ">
      <section>
        <HeroCarousel autoSlide={true} data={heroSliderItems} />
      </section>
      <section>
        <Collections />
      </section>
      <section>
        <New />
      </section>
      <section>
        <FeauturedItems />
      </section>
      <section>
        <LimitedTimeOffer />
      </section>
      <section>
        <TopVendors />
      </section>
      <section>
        <ShopCategory />
      </section>
      <section>
        <NewArrivals />
      </section>
    </div>
  );
};

export default Index;
