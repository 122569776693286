import { Store } from "@/interfaces/account/Home/TopVendors";
import { NextPage } from "next";
import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { URLS } from "@/utils/urls";

interface Props {}

const TopVendors: NextPage<Props> = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [topVendors, setTopVendors] = useState<Store[] | null>(null);

  useEffect(() => {
    fetchTopVendors();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchTopVendors = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(URLS?.vendorsCollectionsUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setTopVendors(data);
      } else {
        console.error("Failed to fetch states");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      console.log(topVendors);
      setIsLoading(false);
    }
  };
  return (
    <div className="px-[98px] sm:px-[14px] md:px-[25px] flex flex-col gap-[30px] ">
      <h4 className="text-[24px] font-montserrat font-[600] ">
        Top Vendors
      </h4>
      <div className="flex justify-evenly gap-[10px] overflow-scroll no-scrollbar">
        {topVendors && !isLoading ? (
          topVendors.map((vendor, index) => (
            <Link
             href={`/store/${vendor.slug}`}
              key={index}
              className="w-[242.5px] sm:w-[220px] sm:h-[65px] h-[80px] py-[23px] px-[25px]  cursor-pointer bg-[#F5F5F5] rounded-[8px] flex gap-[16px] items-center "
            >
              <div>
                <Image
                  height={32}
                  width={37}
                  alt={vendor.slug}
                  src={vendor.display_image_small_thumbnail}
                  className="max-w-[37px] sm:max-w-[22.87px] max-h-[32px] sm:max-h-[20px] "
                />
              </div>
              <div>
                <div className=" truncate font-montserrat text-[14px] font-[400] text-grey-400 ">
                  {vendor.name}
                </div>
                <div className=" truncate text-[14px] sm:text-[12px] font-montserrat font-[400] text-[#999999] ">
                  Products: N/A
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className=""> Loading...</div>
        )}
      </div>
    </div>
  );
};

export default TopVendors;
