import { NextPage } from "next";
import React from "react";
import Link from "next/link";
import { URLS } from "@/utils/urls";
import PaginatedProducts from "../../Products/PaginatedProducts";

interface Props {}

const NewArrivals: NextPage<Props> = ({}) => {
  const newArrivalsUrl = URLS?.newArrivalsUrl;

  return (
    <div className="flex flex-col gap-[23px] px-[50px] sm:px-[14px] md:px-[25px]">
      <PaginatedProducts
        url={newArrivalsUrl}
        title="New Arrivals"
        description="Browse The Collection of Top Products"
      />

      <div className="shop-all-collections">
        <Link href={"/all-products"}>
          <h4 className="text-[14px] text-center text-grey-400 underline underline-offset-1 font-poppins font-[500] cursor-pointer hover:text-orange ">
            Shop All Collections
          </h4>
        </Link>
      </div>
    </div>
  );
};

export default NewArrivals;
