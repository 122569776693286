import React, { useState, useEffect } from "react";
import { NextPage } from "next";
import Image from "next/image";
import Link from "next/link";

interface Props {
  data: {
    src: string;
    texts: {
      header: string;
      sub: string;
      description: string;
    };
  }[];
  autoSlide?: boolean;
  autoSlideInterval?: number;
}

const Carousel: NextPage<Props> = ({
  autoSlide = false,
  autoSlideInterval = 10000,
  data: slides,
}) => {
  const [curr, setCurr] = useState(0);

  const prev = () =>
    setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
  const next = () =>
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, []);
  return (
    <div className="overflow-hidden relative">
      <div
        className="flex transition-transform  ease-out duration-[1.5s]"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {[
          ...slides.map((item, index) => (
            <div className="min-w-[100vw] max-w-[100vw] sm:min-h-[264px] z-20 relative">
              <Image
                src={item.src}
                alt="Hero carousel image"
                width={1920}
                height={264}
                priority={true}
                className="w-full"
                quality={90}
              />
              <div
                key={index}
                className={`z-10 absolute top-1/4 left-24 sm:left-[14px] md:left-[25px]`}
              >
                <h4
                  className={`font-fjallaOne ${
                    curr == index
                      ? "animate-slidein [--slidein-delay:300ms] opacity-0"
                      : "animate-slideout [--slideout-delay:300ms] opacity-100"
                  } text-[60px] sm:text-[24px] md:text-[30px] text-[#555555] font-[400] select-none`}
                >
                  {item.texts.header}
                </h4>
                <h4
                  className={`font-montserrat ${
                    curr == index
                      ? "animate-slidein [--slidein-delay:300ms] opacity-0"
                      : "animate-slideout [--slideout-delay:300ms] opacity-100"
                  } text-[44px] sm:text-[16px] md:text-[20px] text-[#555555] font-[500] select-none`}
                >
                  {item.texts.sub}
                </h4>
                <h4
                  className={`font-montserrat ${
                    curr == index
                      ? "animate-slidein [--slidein-delay:500ms] opacity-0"
                      : "animate-slideout [--slideout-delay:300ms] opacity-100"
                  } animate-slidein sm:text-[12px] md:text-[12px] mb-5 text-[#ABADB0] font-[400] select-none`}
                >
                  {item.texts.description}
                </h4>
                <Link
                  href={`/all-products`}
                  className={`font-montserrat ${
                    curr == index
                      ? "animate-slidein [--slidein-delay:700ms] opacity-0"
                      : "animate-slideout [--slideout-delay:300ms] opacity-100"
                  }  transition-transform	duration-300 max-w-max min-w-max h-max z-50 ease-in-out delay-150 cursor-pointer `}
                >
                  <h4 className="text-[16px] sm:text-[14px] max-w-max md:text-[14px] bg-[#E25822] text-[#FFFFFF] sm:rounded-[4px] md:rounded-[4px] p-[16px] md:p-[8px_12px_8px_12px] sm:p-[8px_12px_8px_12px] font-[500] hover:opacity-75 active:opacity-75">
                    View More
                  </h4>
                </Link>
              </div>
            </div>
          )),
        ]}
      </div>
      <div className="absolute left-0 right-0 sm:hidden md:hidden top-[calc(50%-70px)] flex items-center justify-between p-4">
        <button
          onClick={prev}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
          <Image
            height={32}
            width={32}
            src={"/icons/chevronLeft.svg"}
            alt="Previuos"
          />
        </button>
        <button
          onClick={next}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
          <Image
            height={32}
            width={32}
            src={"/icons/chevronRight.svg"}
            alt="Next"
          />
        </button>
      </div>

      <div className="absolute bottom-4 right-0 left-0">
        <div className="flex items-center justify-center gap-2">
          {slides.map((_, i) => (
            <div
              className={`
              transition-all w-3 h-3 bg-white rounded-full
              ${curr === i ? "p-2" : "bg-opacity-50"}
            `}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
