import LoadingDataSpinner from "@/components/Frontend/LoadingDataSpinner";
import ProductItem from "@/components/Frontend/Products/ProductItem";
import { NextPage } from "next";
import { useEffect, useState, useRef, useCallback } from "react";
import { Product } from "@/interfaces/account/Home/Collections";

interface Props {
  url: string;
  title: string;
  description?: string;
}

const PaginatedProducts: NextPage<Props> = ({ url, title, description }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [nextUrl, setNextUrl] = useState<string | null>(null);

  const observer = useRef<IntersectionObserver | null>(null);

  const fetchProducts = async (productUrl: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(productUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setProducts((prevProducts) => [...prevProducts, ...data.results]);
        setNextUrl(data.next);
      } else {
        console.error("Failed to fetch products");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(url);
  }, [url]);

  const lastProductRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && nextUrl) {
          fetchProducts(nextUrl);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, nextUrl]
  );

  return (
    <div className="flex flex-col gap-[23px] px-[50px] min-h-[80vh] sm:px-[14px] md:px-[25px] mt-[50px]">
      {description ? (
        <div className="gap-[10px] flex flex-col">
          <h4 className="text-center text-[24px] font-montserrat font-[600] ">
            New Arrivals
          </h4>
          <h4 className="text-grey-100 font-poppins text-[14px] font-[400] text-center">
            Browse The Collection of Top Products{" "}
          </h4>
        </div>
      ) : (
        <h2 className="text-center hbm-body-nunito-xl p-5">{title}</h2>
      )}

      {products.length === 0 && !isLoading ? (
        <h2 className="text-center hbm-body-nunito-m-light">
          There are no products
        </h2>
      ) : (
        <>
          <div className="flex justify-center flex-wrap">
            {products.map((item, index) => {
              if (products.length === index + 1) {
                return (
                  <div ref={lastProductRef} key={item.id}>
                    <ProductItem product={item} />
                  </div>
                );
              } else {
                return <ProductItem product={item} key={item.id} />;
              }
            })}
          </div>
          {isLoading && (
            <div className="flex justify-center mt-4">
              <LoadingDataSpinner />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PaginatedProducts;
