import { Product } from "@/interfaces/account/Home/Collections";
import { NextPage } from "next";
import { useEffect, useState } from "react";
import Image from "next/image";
import Slickslider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Link from "next/link";
import { URLS } from "@/utils/urls";
import ProductItem from "../../Products/ProductItem";

interface Props {}

const Collections: NextPage<Props> = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [collections, setCollections] = useState<Product[] | null>(null);
  const [filteredCollection, setFilteredCollections] = useState<
    Product[] | null
  >(null);
  const [activeCollection, setActiveCollection] = useState("All");

  useEffect(() => {
    fetchCollections();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCollections = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(URLS?.collectionsUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCollections(data);
        setFilteredCollections(data);
      } else {
        console.error("Failed to fetch states");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function filterProductsByCategoryName(
    products: Product[],
    categoryName: string
  ): Product[] {
    return products.filter(
      (product) => product.store.category === categoryName
    );
  }

  const handleActiveCollectionChange = (collection: string) => {
    if (!collections) {
      return;
    }
    if (collection == "All") {
      setFilteredCollections(collections);
    } else {
      const filtered_Collections = filterProductsByCategoryName(
        collections,
        collection
      );
      setFilteredCollections(filtered_Collections);
    }
    setActiveCollection(collection);
  };

  return (
    <div className="flex flex-col gap-[23px] px-[50px] sm:px-[14px] md:px-[25px]">
      <div className="gap-[10px] flex flex-col">
        <h4 className="text-center text-[24px] font-montserrat font-[600]">
          Collections
        </h4>
        <Link
          href={"/all-products"}
          className="text-grey-100 font-poppins text-[14px] font-[400] text-center"
        >
          Browse The Collection of Top Products
        </Link>
        <div className="flex justify-center sm:justify-start md:justify-start gap-[20px] px-5 overflow-x-scroll no-scrollbar">
          <div
            onClick={() => handleActiveCollectionChange("All")}
            className={`text-[14px] cursor-pointer select-none font-poppins hover:text-orange ${
              activeCollection == "All" ? "text-orange" : "text-grey-400"
            }`}
          >
            All
          </div>
          {Array.from(
            new Set(collections?.map((item) => item.store.category))
          ).map((item, index) => (
            <div
              onClick={() => handleActiveCollectionChange(item)}
              className={`text-[14px] cursor-pointer select-none font-poppins  hover:text-orange whitespace-nowrap ${
                activeCollection == item ? "text-orange" : "text-grey-400"
              }`}
              key={index}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div>
        {filteredCollection && !isLoading ? (
          filteredCollection?.length !== 1 ? (
            <Slickslider
              dots={false}
              className="slider variable-width"
              infinite={true}
              variableWidth={true}
              slidesToShow={1}
              slidesToScroll={2}
              // centerMode={true}
              prevArrow={
                <Image
                  height={32}
                  width={32}
                  alt="Previous"
                  src={"/icons/chevronLeft.svg"}
                />
              }
              nextArrow={
                <Image
                  height={32}
                  width={32}
                  alt="Next"
                  src={"/icons/chevronRight.svg"}
                />
              }
              responsive={[
                // {
                //   breakpoint: 1200,
                //   settings: {
                //   },
                // },
                // {
                //   breakpoint: 1024,
                //   settings: {
                //     nextArrow: undefined,
                //     prevArrow: undefined,
                //   },
                // },
                {
                  breakpoint: 600,
                  settings: {
                    nextArrow: <div className="hidden"></div>,
                    prevArrow: <div className="hidden"></div>,
                  },
                },
              ]}
            >
              {filteredCollection?.map((item, index) => (
                <ProductItem product={item} key={index} />
              ))}
            </Slickslider>
          ) : (
            filteredCollection?.length === 1 && filteredCollection[0] ? (
              <ProductItem product={filteredCollection[0]} />
            ) : (
              <div>No product found</div>
            )
          )
        ) : (
          <div>Loading..</div>
        )}
      </div>

      <div className="shop-all-collections">
        <Link href={"/all-products"}>
          <h4 className="text-[14px] text-center text-grey-400 underline underline-offset-1 font-poppins font-[500] cursor-pointer hover:text-orange ">
            Shop All Collections
          </h4>
        </Link>
      </div>
    </div>
  );
};

export default Collections;
