import { NextPage } from "next";
import { useState } from "react";
import Image from "next/image";
import Link from "next/link";

interface Props {}

const New: NextPage<Props> = ({}) => {
  const [newPageItems] = useState([
    {
      title: "New Arrivals",
      desc: "Mens sport shoes",
      src: "/images/newArrival.svg",
      url: "/new-arrivals",
    },
    {
      title: "New Trending",
      desc: "Smart watches",
      src: "/images/newTrending.svg",
      url: "/trending",
    },
  ]);
  return (
    <div className="px-[50px] sm:px-[14px] md:px-[25px] flex sm:flex-col md:flex-col items-center gap-[20px] md:gap-[20px] justify-center">
      {newPageItems.map((pageItem, index) => (
        <div key={index} className="relative group rounded-md overflow-hidden">
          <Image height={319} width={522} src={pageItem.src} alt="" />
          <div className="absolute top-1/4 left-8 flex flex-col gap-[5px] ">
            <h4 className=" font-montserrat capitalize text-[20px] sm:text-[18px] md:text-[18px] font-[400] text-grey-500 ">
              {pageItem.title}
            </h4>
            <h4 className=" text-[20px] w-[140px] uppercase font-[700] sm:text-[18px] md:text-[18px] text-grey-500 font-montserrat ">
              {pageItem.desc}
            </h4>
          </div>
          <div className=" rounded-md absolute flex transition-all duration-700 scale-75 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:scale-100 bg-[#1f1c1c80] inset-0 justify-center items-center ">
            <Link
              href={pageItem.url}
              className="bg-orange py-2 px-6 font-montserrat hover:bg-grey-400 rounded-md text-white text-[14px] outline-none "
            >
              View More
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default New;
